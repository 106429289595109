@tailwind base;
@tailwind components;
@tailwind utilities;
@import '../shared/styles/input.css';
@import '../shared/styles/calendar.css';
@import '../shared/styles/productDetailsSwiper.css';
@import '../shared/styles/markdown.css';

.hide-input-arrows::-webkit-outer-spin-button,
.hide-input-arrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.hide-input-arrows {
  appearance: textfield;
  -moz-appearance: textfield;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  height: 100dvh;
  font-size: 16px;
}

body {
  font-family: var(--font-exo);
  min-height: 100%;
  color: white;
  scroll-behavior: smooth;
  overflow-x: hidden;
  @apply bg-neutral1000;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    margin-bottom: 5px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 24px;
    @apply bg-neutral700;
  }
}

button {
  white-space: nowrap;
}

.hide-input-arrows::-webkit-outer-spin-button,
.hide-input-arrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.hide-input-arrows {
  appearance: textfield;
  -moz-appearance: textfield;
}

.noScroll {
  overflow: hidden;

  @media screen and (min-width: 768px) {
    overflow-y: auto;
  }

  .modal {
    overflow: hidden;
  }
}

.paymentHistoryTable {
  td {
    @apply h-16 last-of-type:border-0;
  }
}

.threadsTable {
  td {
    @apply h-14 py-8;
  }
  td:first-child {
    @apply align-top;
  }

  th:first-child {
    @apply !w-12;
  }

  th:last-child {
    @apply !w-12;
  }
}

@layer utilities {
  .heading-xxxlarge {
    font-family: var(--font-space-grotesk);
    font-size: 2rem;
    font-weight: bold;
    line-height: 2.5rem;

    @media screen and (min-width: 1024px) {
      line-height: 4rem;
      font-size: 3rem;
    }
  }

  .heading-xxlarge {
    font-family: var(--font-space-grotesk);
    font-size: 3rem;
    font-weight: bold;
    line-height: 3rem;
  }

  .heading-large {
    font-family: var(--font-space-grotesk);
    font-size: 2.5rem;
    font-weight: bold;
    line-height: 3rem;
  }

  .heading-medium {
    font-family: var(--font-space-grotesk);
    font-size: 2rem;
    font-weight: bold;
    line-height: 2.5rem;
  }

  .heading--mid {
    font-family: var(--font-space-grotesk);
    font-size: 1.875rem;
    font-weight: bold;
    line-height: 2.25rem;
  }

  .heading-small {
    font-family: var(--font-space-grotesk);
    font-size: 1.75rem;
    font-weight: bold;
    line-height: 2.25rem;
  }

  .heading-xsmall {
    font-family: var(--font-space-grotesk);
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 2rem;
  }

  .heading-xxsmall {
    font-family: var(--font-space-grotesk);
    font-size: 1.375rem;
    font-weight: bold;
    line-height: 2rem;
  }

  .font-xxlarge {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .font-xlarge {
    font-size: 1.125rem;
    line-height: 1.625rem;
  }

  .font-large {
    font-size: 1rem;
    line-height: 1.75rem;
  }

  .font-medium {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  .font-small {
    font-size: 0.75rem;
    line-height: 1rem;
  }

  .font-xsmall {
    font-size: 0.6875rem;
    line-height: 1rem;
  }

  .label-xlarge {
    font-size: 0.875rem;
    line-height: 1.25rem;
    text-transform: uppercase;
    font-weight: 700;
  }

  .label-large {
    font-size: 0.8125rem;
    line-height: 1rem;
    text-transform: uppercase;
    font-weight: 700;
  }

  .label-medium {
    font-size: 0.75rem;
    line-height: 1.625rem;
    text-transform: uppercase;
    font-weight: 700;
  }

  .label-small {
    font-size: 0.6875rem;
    line-height: 1rem;
    text-transform: uppercase;
    font-weight: 700;
  }

  .customTransition {
    @apply transition duration-300 ease-out;
  }

  .transitionColor {
    @apply transition-colors duration-300 ease-out;
  }

  .roundedBlTr {
    @apply rounded-bl-md rounded-tr-md;
  }

  .roundedBrTl {
    @apply rounded-br-lg rounded-tl-lg;
  }

  .flexCenter {
    @apply flex items-center justify-center;
  }

  .flexStart {
    @apply flex items-center justify-start;
  }

  .absoluteCenter {
    @apply absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2;
  }

  .scrollbarHide {
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .green-scrollbar {
    /* Removed because it caused windows scrollbar to not be consistent with other device scrollbars */
    /* scrollbar-width: thin;
    scrollbar-color: #33ff85 transparent !important; */

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
      margin-top: 5px;
      margin-bottom: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #33ff85;
      border-radius: 24px;
    }
  }

  .swiperPolygonNotch {
    /* 🙌 https://css-generators.com/custom-corners/ */
    clip-path: polygon(
      0 0,
      calc(100% - 20px) 0,
      100% 20px,
      100% 100%,
      20px 100%,
      0 calc(100% - 20px)
    );
  }

  .polygon-clipPath {
    clip-path: polygon(0 26%, 51% 0, 100% 26%, 100% 70%, 50% 100%, 0 70%);
  }

  .ellipsis {
    @apply overflow-hidden text-ellipsis whitespace-nowrap;
  }
}

.invertedBorderRadiusTr {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-image: radial-gradient(
    circle at 100% 0,
    #0c111d 9px,
    rgb(0, 0, 0, 0) 9px
  );
}

@layer components {
  .productCardImageGradient:after {
    position: absolute;
    content: '';
    height: 100%;
    width: 100%;
    background: linear-gradient(180deg, #090e1900 9.06%, #000000 99.65%),
      no-repeat;
  }

  .paginationIcon {
    @apply flexCenter label-medium xs:label-large size-6 cursor-pointer border-b border-transparent text-neutral500 transition-all xs:size-10;

    &&:hover {
      @apply bg-neutral700 text-white;
    }

    &.active {
      @apply border-green500 bg-neutral700 text-white;
    }

    &.disabled {
      @apply cursor-not-allowed;
    }
  }

  .nav-bar-icon {
    @apply flexCenter transitionColor h-full w-16 cursor-pointer border-x border-neutral700 bg-neutral900 text-neutral400 hover:text-white;
  }

  /*Connect button*/
  .animatedBorderButton {
    border: 1px solid transparent;

    &::before {
      content: '';
      position: absolute;
      height: 100%;
      width: 100%;
      border-radius: inherit;
      z-index: -2;
      @apply bg-neutral600;
    }

    &::after {
      content: '';
      position: absolute;
      z-index: -1;
      inset: 1px;
      border-top-right-radius: 5px;
      border-bottom-left-radius: 5px;
      @apply bg-neutral900 xl:bg-neutral1000;
    }

    @media screen and (min-width: 1280px) {
      &:hover::before,
      .continueAnimation::before {
        width: 110%;
        background: linear-gradient(#000000, #30ff83, #000000);
        animation: rotate 2.5s linear infinite;
      }
    }
  }

  /*Auth button*/
  .authButton {
    @apply ml-auto text-xs font-bold;
  }

  .tabButton {
    @apply label-small sm:label-large h-full border-b border-transparent px-2 text-neutral200 transition-all max-sm:w-full sm:px-6;

    &:hover {
      @apply border-neutral400 bg-neutral800 text-neutral300;
    }

    &.active {
      @apply pointer-events-none cursor-default border-green500 text-white;
    }
  }

  /*react-syntax-highlighter*/
  .markup {
    .hljs-attr {
      @apply text-base font-normal text-neutral300;
    }

    .hljs-string {
      @apply text-base font-normal text-yellow500;
    }

    .hljs-number {
      @apply text-base font-normal text-green500;
    }
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.veriffBtn {
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
  z-index: 1;

  > form {
    display: flex;
    width: 100%;
    height: 100%;

    > input {
      height: 100%;
      padding: 0;
    }

    > p {
      display: none;
    }
  }
}

input:placeholder-shown {
  text-overflow: ellipsis;
}

input[type='radio'] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

input[type='radio'] + span {
  position: relative;
  padding-left: 22px;
  cursor: pointer;
  display: inline-block;
}

input[type='radio'] + span::before,
input[type='radio'] + span::after {
  content: '';
  position: absolute;
  border-radius: 50%;
  transition: all 0.2s ease;
}

input[type='radio'] + span::before {
  width: 14px;
  height: 14px;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  border: 3px solid;
  @apply border-neutral700;
  background: transparent;
}

input[type='radio']:checked + span::before {
  border-color: #33ff85;
  background: transparent;
}

input[type='radio'] + span::after {
  width: 8px;
  height: 8px;
  left: 3px;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  opacity: 0;
}

input[type='radio']:checked + span::after {
  background: #003d1c;
  opacity: 1;
}

input[type='checkbox'] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

input[type='checkbox'] + span {
  position: relative;
  padding-left: 40px;
  cursor: pointer;
  display: inline-block;
}

input[type='checkbox'] + span::before {
  content: '';
  position: absolute;
  width: 14px;
  height: 14px;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  border: 1px solid;
  @apply border-neutral700;
  background: transparent;
  border-radius: 2px;
  transition: all 0.2s ease;
}

input[type='checkbox']:checked + span::before {
  border-color: #33ff85;
  background: #003d1c;
  background-image: url('../assets/icons/check-mark.svg');
  background-size: 8px 8px;
  background-position: center;
  background-repeat: no-repeat;
}

input[type='checkbox'] + span::after {
  content: '';
  position: absolute;
  width: 6px;
  height: 10px;
  border: solid transparent;
  border-width: 0 1px 1px 0;
  left: 5px;
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
  opacity: 0;
}

input[type='checkbox']:checked + span::after {
  opacity: 0;
}

input[type='password']::-ms-reveal,
input[type='password']::-ms-clear {
  display: none;
}

.hide-input-arrows::-webkit-outer-spin-button,
.hide-input-arrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.hide-input-arrows {
  appearance: textfield;
  -moz-appearance: textfield;
}

.greenToRedGradient {
  background: linear-gradient(to bottom, #33ff85 0%, #ef4444 100%);
}

.redToGreenGradient {
  background: linear-gradient(to bottom, #ef4444 0%, #33ff85 100%);
}

.greenToYellowGradient {
  background: linear-gradient(to bottom, #33ff85 0%, #eab308 100%);
}

.triangle {
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 10.4px solid #33ff85;
}

.commentLine {
  position: relative;
}

.commentLine::before {
  content: '';
  position: absolute;
  height: calc(100% - 34px);
  width: 1px;
  top: 0;
  left: 20px;
  @apply bg-neutral700;
}

.commentLine::after {
  content: '';
  position: absolute;
  bottom: 7px;
  left: 19.5px;
  width: 37px;
  height: 28px;
  background: url('../../src/assets/icons/forum/comment-line.svg') no-repeat;
}

.replyLine {
  position: relative;
}

.replyLine::before {
  content: '';
  position: absolute;
  top: 16px;
  left: -37px;
  width: 50px;
  height: 32px;
  background: url('../../src/assets/icons/forum/reply-line.svg') no-repeat;
}

.chatNotification {
  position: relative;
}

.chatNotification::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(-50%, -50%);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  @apply bg-green500;
  box-shadow: 0px 0px 4px rgba(51, 255, 133, 0.4);
}

.forumLoginButton {
  @apply flex min-h-[200px] min-w-[200px] flex-1 flex-col items-center justify-center gap-4 border border-neutral700 py-10 text-neutral200 transition-all duration-300;
}

.forumLoginButton:hover {
  @apply border-neutral500 text-white;
}

.forumLoginButton:active {
  @apply border-green500 bg-neutral700;
}

.forumLoginButton:disabled {
  @apply pointer-events-none bg-neutral700 text-neutral800;
}

/* TODO chat input shadow update boja*/
.chat-input-shadow {
  box-shadow:
    0px -11px 25px 0px rgba(2, 1, 4, 0.1),
    0px -41px 46px 0px rgba(2, 1, 4, 0.19),
    0px -103px 62px 0px rgba(2, 1, 4, 0.05),
    0px -183px 73px 0px rgba(2, 1, 4, 0.01),
    0px -286px 80px 0px rgba(2, 1, 4, 0);
}

.overflow-wrap-anywhere {
  overflow-wrap: anywhere;
}
